<template>
  <div class="form-section" :class="$store.state['App-UiColorSchema-api'].currentSchemaClasses">
    <div @click="visibility = !visibility" class="title" style="color: #FBD91E">2 - Taxes
      <i :class="{'el-icon-arrow-down': visibility, 'el-icon-arrow-right' : (!visibility) == true}"></i>
    </div>

    <div v-show="visibility">
      <alternative-text info="You have to select a province from Location Section" v-if="!provinceId">
        please select a province
      </alternative-text>
      <span v-else>
      <!-- HST Section    -->
      <span>
        <el-row>
            <el-col :class="$langConfig.cssClass" style="width: 220px;">
                <dg-select-field
                    :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                    :field_span="11"
                    :label_span="13"
                    :options="dropdownTaxReportingPeriods"
                    :translateLabel='false'
                    :translateLabels="false"
                    field_mark="required"
                    label="Accounting Type"
                    name="account_accounting_type_id"
                    placeholder=""
                    rules="required"></dg-select-field>
          </el-col>

            <el-col :class="$langConfig.cssClass" style="width: 135px;margin-left: 10px;">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="12"
                :label_span="12"
                :options="dropdownMonths"
                :translateLabel='false'
                :translateLabels="false"
                field_mark="required"
                label="Year End"
                name="account_accounting_year_end"
                placeholder=""
                rules="required"></dg-select-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 25px; margin-left: 10px;">
            <dg-single-checkbox-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="8"
                :labelThenField="true"
                :label_span="16"
                :translateLabel='false'
                :valueFormatter="(val) => { return (val)? 1 : 0}"
                @amUpdated="(p, val) =>  { t2Checkbox = val}"
                field_mark=""
                label="T2"
                name="tax_t2"
                rules=""></dg-single-checkbox-field>
            <!-- <field-info width="80%">description here.</field-info>-->
          </el-col>

          <!--<el-col :class="$langConfig.cssClass" style="width: 200px;margin-left: 20px" v-if="t2Checkbox">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="13"
                :label_span="11"
                :options="TaxFilingTypeDropdown"
                :translateLabel='false'
                :translateLabels="false"
                @amUpdated="(p, val) =>  { t2FilingType = val}"
                field_mark="required"
                label="Filing Type"
                name="tax_t2_filing_type_id"
                placeholder=""
                rules="required"></dg-select-field>
          </el-col>-->

          <el-col :class="$langConfig.cssClass" style="width:210px" v-if="t2Checkbox">
              <dg-select-field
                  :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                  :field_span="12"
                  :label_span="12"
                  :options="dropdownTaxReportingPeriods"
                  :translateLabel='false'
                  :translateLabels="false"
                  @amUpdated="(p, val) =>  {t2ReportingType = val}"
                  field_mark="required"
                  label="Reporting Type"
                  name="tax_t2_report_type_id"
                  placeholder=""
                  rules=""></dg-select-field>
            </el-col>

          <!--<el-col :class="$langConfig.cssClass" style="width: 160px;margin-left: 10px;"
                  v-if="t2ReportingType && t2ReportingType != 1">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="10"
                :label_span="13"
                :options="dropdown12Month"
                :translateLabel='false'
                :translateLabels="false"
                @amUpdated="(p, val) =>  { hstReportingSchedulePeriod = val}"
                field_mark="required"
                label="Period Due"
                name="tax_t2_report_period_end"
                placeholder=""
                rules="required"></dg-select-field>
          </el-col>-->

          <el-col :class="$langConfig.cssClass" style="width: 35px;margin-left: 15px;">
            <dg-single-checkbox-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="10"
                :labelThenField="true"
                :label_span="14"
                :translateLabel='false'
                :valueFormatter="(val) => { return (val)? 1 : 0}"
                @amUpdated="(p, val) =>  { hst = val}"
                field_mark=""
                label="T5"
                name="tax_t5"
                rules=""></dg-single-checkbox-field>
            <!--        <field-info width="80%">description here.</field-info>-->
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 50px;margin-left: 15px;">
            <dg-single-checkbox-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="12"
                :labelThenField="true"
                :label_span="12"
                :translateLabel='false'
                :valueFormatter="(val) => { return (val)? 1 : 0}"
                @amUpdated="(p, val) =>  { hst = val}"
                field_mark=""
                label="T4A"
                name="tax_t4a"
                rules=""></dg-single-checkbox-field>
            <!--        <field-info width="80%">description here.</field-info>-->
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 100px;margin-left: 0px;">
            <dg-single-checkbox-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="12"
                :labelThenField="true"
                :label_span="12"
                :translateLabel='false'
                :valueFormatter="(val) => { return (val)? 1 : 0}"
                @amUpdated="(p, val) =>  { hst = val}"
                field_mark=""
                label="client ?"
                name="account_on_tax_check_management"
                rules=""></dg-single-checkbox-field>
            <!--        <field-info width="80%">description here.</field-info>-->
          </el-col>
        </el-row>

        <el-row class="mt-2">
          <el-col :class="$langConfig.cssClass" style="width:100px;" v-if="provinceId == 1000">
            <dg-single-checkbox-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="2"
                :labelThenField="true"
                :label_span="22"
                :translateLabel='false'
                :valueFormatter="(val) => { return (val)? 1 : 0}"
                @amUpdated="(p, val) =>  {taxCheckbox = val}"
                field_mark=""
                label="GST-HST-TVQ"
                name="tax_taxes_to_be_done_gst_hst_tvq"
                rules="required"></dg-single-checkbox-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width:30px;" v-if="provinceId == 1">
            <dg-single-checkbox-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="2"
                :labelThenField="true"
                :label_span="22"
                :translateLabel='false'
                :valueFormatter="(val) => { return (val)? 1 : 0}"
                @amUpdated="(p, val) =>  {taxCheckbox = val}"
                field_mark=""
                label="HST"
                name="tax_taxes_to_be_done_gst_hst_tvq"
                rules="required"></dg-single-checkbox-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width:70px;" v-if="provinceId == 2">
            <dg-single-checkbox-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="2"
                :labelThenField="true"
                :label_span="22"
                :translateLabel='false'
                :valueFormatter="(val) => { return (val)? 1 : 0}"
                @amUpdated="(p, val) =>  {taxCheckbox = val}"
                field_mark=""
                label="GST-TVQ"
                name="tax_taxes_to_be_done_gst_hst_tvq"
                rules="required"></dg-single-checkbox-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 200px;margin-left: 20px" v-if="taxCheckbox">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="13"
                :label_span="11"
                :options="TaxFilingTypeDropdown"
                :translateLabel='false'
                :translateLabels="false"
                @amUpdated="(p, val) =>  { taxReportingType = val}"
                field_mark="required"
                label="Filing Type"
                name="tax_filing_type_id"
                placeholder=""
                rules="required"></dg-select-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 220px;margin-left: 10px"
                  v-if="taxCheckbox && taxReportingType">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="12"
                :label_span="12"
                :options="dropdownTaxReportingPeriods"
                :translateLabel='false'
                :translateLabels="false"
                @amUpdated="(p, val) =>  { taxReportingPeriod = val}"
                field_mark="required"
                label="Reporting Type"
                name="tax_reporting_period_type_id"
                placeholder=""
                rules="required"></dg-select-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 160px;margin-left: 10px;"
                  v-if="taxCheckbox && taxReportingPeriod && taxReportingPeriod != 1">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="10"
                :label_span="13"
                :options="dropdown12Month"
                :translateLabel='false'
                :translateLabels="false"
                field_mark="required"
                label="Period Due"
                name="tax_taxes_to_be_done_report_period_end_value"
                placeholder=""
                rules="required"></dg-select-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 140px;" v-if="taxCheckbox">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="13"
                :label_span="11"
                :options="dropdownPriority"
                :translateLabel='false'
                :translateLabels="false"
                field_mark="required"
                label="Priority"
                name="tax_hst_priority_id"
                placeholder="Priority"
                rules="required"></dg-select-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 250px;margin-left: 10px;" v-if="taxCheckbox">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="11"
                :label_span="13"
                :options="dropdownReportingMethod"
                :translateLabel='false'
                :translateLabels="false"
                field_mark="required"
                label="Reporting Method"
                name="tax_hst_reporting_method"
                placeholder=""
                rules="required"></dg-select-field>
          </el-col>
        </el-row>

        <el-row class="mt-2" v-if="provinceId == 2 || provinceId == 1000">
          <el-col :class="$langConfig.cssClass" style="width: 240px;" v-if="provinceId == 2 || provinceId == 1000">
            <tvq-number :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode"></tvq-number>
          </el-col>
          <el-col :class="$langConfig.cssClass" style="width: 180px;" v-if="provinceId == 2 || provinceId == 1000">
            <neq-number :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled" :editMode="editMode"></neq-number>
          </el-col>
          <el-col :class="$langConfig.cssClass" style="width: 290px;" v-if="provinceId == 2 || provinceId == 1000">
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="16"
                :label_span="8"
                :translateLabel='false'
                field_mark="required"
                label="Click Secure"
                name="tax_neq_click_secure"
                placeholder=""
                rules="required"></dg-text-field>
          </el-col>
          <el-col :class="$langConfig.cssClass" style="width: 200px;margin-left: 10px;"
                  v-if="provinceId == 2 || provinceId == 1000">
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="15"
                :label_span="9"
                :translateLabel='false'
                field_mark="required"
                label="Password"
                name="tax_neq_click_secure_password"
                placeholder=""
                rules="required"></dg-text-field>
          </el-col>
        </el-row>

        <!--<el-row>
          &lt;!&ndash;<el-col :class="$langConfig.cssClass" style="width: 160px">
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="16"
                :label_span="7"
                :translateLabel='false'
                :valueFormatter="(val) => { return $services['NumUtil'].rmNonDigits(val)}"
                @amUpdated=" (p, val) => { p.field.field_value = $services['NumUtil'].format(val)}"
                field_mark="required"
                label="AVG"
                name="tax_hst_average"
                placeholder="e.g 1,200,000"
                rules="required"></dg-text-field>
          </el-col>&ndash;&gt;

          <el-col :class="$langConfig.cssClass" style="width: 160px;margin-left: 10px;" v-if="hst">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="10"
                :label_span="13"
                :options="dropdown12Month"
                :translateLabel='false'
                :translateLabels="false"
                @amUpdated="(p, val) =>  { hstReportingSchedulePeriod = val}"
                field_mark="required"
                label="Period Due"
                name="tax_hst_schedule_period_id"
                placeholder=""
                rules="optional"
                v-if="hstReportingSchedule == 3"></dg-select-field>
          </el-col>
        </el-row>-->
      </span>

        <!-- Payroll -->
      <el-row class="mt-2">
          <el-col :class="$langConfig.cssClass" style="width:70px">
            <dg-single-checkbox-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="5"
                :labelThenField="true"
                :label_span="17"
                :translateLabel='false'
                :valueFormatter="(val) => { return (val)? 1 : 0}"
                @amUpdated=" (p, val) => {hsaPayroll = val}"
                field_mark=""
                label="Payroll"
                name="tax_payroll"
                rules=""></dg-single-checkbox-field>
            </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 140px;margin-left: 10px" v-if="hsaPayroll">
          <dg-select-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="14"
              :label_span="10"
              :options="dropdownPriority"
              :translateLabel='false'
              :translateLabels="false"
              field_mark="required"
              label="Priority"
              name="tax_payroll_priority_id"
              placeholder="Priority"
              rules="required"></dg-select-field>
        </el-col>

          <el-col :class="$langConfig.cssClass" style="width:60px;margin-left: 10px"
                v-if="provinceId == 1 || provinceId == 1000">
          <dg-single-checkbox-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="9"
              :labelThenField="true"
              :label_span="15"
              :translateLabel='false'
              :defaultValue="this.control.dataModel.tax_wsib == 1"
              :valueFormatter="(val) => { return (val)? 1 : 0}"
              @amUpdated=" (p, val) => {has_wsib = val}"
              field_mark=""
              label="WSIB"
              name="tax_wsib"
              rules=""></dg-single-checkbox-field>
        </el-col>

          <el-col :class="$langConfig.cssClass" style="width:240px; margin-left: 10px;"
                 v-if="has_wsib && (provinceId == 1 || provinceId == 1000)">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="12"
                :label_span="12"
                :options="dropdownTaxReportingPeriods"
                :translateLabel='false'
                :translateLabels="false"
                @amUpdated=" (p, val) => {wsib_schedule_id = val}"
                field_mark="required"
                label="Reporting Type"
                name="tax_wsib_reporting_period_type"
                placeholder=""
                rules=""></dg-select-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width:160px;margin-left: 10px;"
                v-if="has_wsib && wsib_schedule_id != 1 && wsib_schedule_id != null && (provinceId == 1 || provinceId == 1000)">
            <dg-select-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="10"
                :label_span="13"
                :options="dropdown12Month"
                :translateLabel='false'
                :translateLabels="false"
                field_mark="required"
                label="Period Due"
                name="tax_wsib_report_period_end_value"
                placeholder=""
                rules="required"></dg-select-field>
        </el-col>

          <el-col :class="$langConfig.cssClass" style="width:150px;margin-left: 10px;" v-if="has_wsib && (provinceId == 1 || provinceId == 1000)">
          <dg-text-field
              :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
              :field_span="12"
              :label_span="12"
              :translateLabel='false'
              field_mark="required"
              maxLength="7"
              label="Account"
              name="tax_wsib_account"
              placeholder=""
              rules="required"></dg-text-field>
          </el-col>

          <el-col :class="$langConfig.cssClass" style="width:120px;margin-left: 10px;" v-if="has_wsib && (provinceId == 1 || provinceId == 1000)">
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="16"
                :label_span="8"
                :translateLabel='false'
                field_mark="required"
                maxLength="8"
                label="Firm"
                name="tax_wsib_firm"
                placeholder=""
                rules="required"></dg-text-field>
            </el-col>

          <el-col :class="$langConfig.cssClass" style="width: 70px; margin-left: 10px;" v-if="provinceId == 2 || provinceId == 1000">
              <dg-single-checkbox-field
                  :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                  :field_span="8"
                  :labelThenField="true"
                  :label_span="16"
                  :translateLabel='false'
                  :valueFormatter="(val) => { return (val)? 1 : 0}"
                  @amUpdated="(p, val) =>  { taxCNESST = val}"
                  field_mark=""
                  label="CNESST"
                  name="tax_cnesst"
                  rules=""></dg-single-checkbox-field>
            </el-col>
        </el-row>

        <el-row>
          <el-col :class="$langConfig.cssClass" :span="(!editMode)? 24 : 24">
            <dg-text-field
                :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                :field_span="24"
                :label_span="24"
                :translateLabel='false'
                field_mark=""
                inputPrepend="notes"
                label=""
                name="tax_section_notes"
                placeholder=""
                rules=""></dg-text-field>
          </el-col>
      </el-row>

        <!-- WSIB -->
        <!--<el-row>
           <el-col :class="$langConfig.cssClass" style="width:140px" v-if="has_wsib">
             <dg-single-checkbox-field
                 :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                 :defaultValue="has_wsib_csst"
                 :field_span="4"
                 :label_span="16"
                 :translateLabel='false'
                 field_mark=""
                 label="CSST"
                 name="wsib_has_csst"
                 rules="required"></dg-single-checkbox-field>
           </el-col>
      </el-row>-->

        <!--      <hr v-if="provinceId == 100">-->

        <!-- GST TVQ Section    -->
        <!--<span v-if="provinceId == 2 || provinceId == 100">
          <el-row>
            <el-col :class="$langConfig.cssClass" :span="(!editMode)? 8 : 8">
              <dg-single-checkbox-field
                  :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                  :field_span="6"
                  :label_span="16"
                  :translateLabel='false'
                  @amUpdated="(p, val) =>  { gstTvq = val}"
                  field_mark=""
                  label="GST-TVQ"
                  name="tax_has_gst"
                  rules="required"></dg-single-checkbox-field>

            </el-col>

            <el-col :class="$langConfig.cssClass" :span="(!editMode)? 16 : 16" v-if="gstTvq">
              <dg-text-field
                  :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                  :field_span="15"
                  :label_span="9"
                  :translateLabel='false'
                  :valueFormatter="(val) => { return $services['NumUtil'].rmNonDigits(val)}"
                  @amUpdated=" (p, val) => { p.field.field_value = $services['NumUtil'].format(val)}"
                  field_mark="required"
                  label="Average"
                  name="tax_gst_average"
                  placeholder="e.g 1,200,000"
                  rules="required"></dg-text-field>
            </el-col>
          </el-row>
          <el-row>
            <el-col :class="$langConfig.cssClass" :offset="8" :span="(!editMode)? 16 : 16" v-if="gstTvq">
              <dg-select-field
                  :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                  :field_span="15"
                  :label_span="9"
                  :options="dropdownTaxReportingPeriods"
                  :translateLabel='false'
                  :translateLabels="false"
                  @amUpdated="(p, val) =>  { gstTvqReportingSchedule = val}"
                  field_mark="required"
                  label="Schedule"
                  name="tax_gst_schedule_id"
                  placeholder="Schedule"
                  rules="optional"></dg-select-field>

              <dg-select-field
                  :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                  :field_span="15"
                  :label_span="9"
                  :options="dropdown12Month"
                  :translateLabel='false'
                  :translateLabels="false"
                  @amUpdated="(p, val) =>  { gstTvqReportingSchedulePeriod = val}"
                  field_mark="required"
                  label="Period"
                  name="tax_gst_schedule_period_id"
                  placeholder="Schedule"
                  rules="optional"
                  v-if="gstTvqReportingSchedule == 3"></dg-select-field>
            </el-col>

          </el-row>
        </span>-->

    </span>
    </div>

  </div>
</template>

<script>
import json12month from '@/json/dropdown/12Month.json'
import jsonTaxReportingPeriods from '@/json/dropdown/TaxReportingPeriods.json'
import jsonPriority from "@/json/dropdown/Priority.json";
import TaxFilingTypeJson from "@/json/dropdown/TaxFilingType.json";
import reportingMethodJson from "@/json/dropdown/ReportingMethod.json";
import json12Month from "@/json/dropdown/12Month.json";
export default {
  components: {
    'tvq-number': () => import('@/components/tvq-number/View'),
    'neq-number': () => import('@/components/neq-number/View'),
    'alternative-text': () => import('@/components/alternative-text/View')
  },
  data() {
    return {
      taxCNESST: false,
      dropdownReportingMethod: reportingMethodJson,
      hsaPayroll: null,
      dropdownPriority: jsonPriority,
      t2Checkbox: null,
      taxReportingType: null,
      taxReportingPeriod: null,
      taxCheckbox: null,
      t2ReportingType: null,
      taxesToBeDoneReportTypeId: null,
      dropdownMonths: json12Month,
      TaxFilingTypeDropdown: TaxFilingTypeJson,
      has_wsib: null,
      visibility: true,
      dropdownTaxReportingPeriods: jsonTaxReportingPeriods,
      dropdown12Month: json12month,
      hst: null,
      hstReportingSchedule: null,
      gstTvq: null,
      gstTvqReportingSchedule: null,
      gstTvqReportingSchedulePeriod: null,
      wsib_schedule_id: null
    }
  },
  methods: {
    taxesToBeDoneReportChanged(p, val) {
      this.taxesToBeDoneReportTypeId = val
    }
  },
  props: {
    has_direct_deposit: {
      required: true
    },
    provinceId: {
      required: true
    },
    control: {
      required: true
    },
    editMode: {
      required: true
    }
  }
}
</script>
